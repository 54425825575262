<template>
  <template v-for="item in detailData.list" :key="item.id">
    <div class="detailData">
       <ul>
          <li>sn</li>
          <li>{{ item["sn"] }}</li>
       </ul>
       <ul>
          <li>userId</li>
          <li>{{ item["userId"] }}</li>
       </ul>
       <ul>
          <li>orderId</li>
          <li>{{ item["orderId"] }}</li>
       </ul>
       <ul>
          <li>gameId</li>
          <li>{{ item["gameId"] }}</li>
       </ul>
       <ul>
          <li>gameType</li>
          <li>{{ item["gameType"] }}</li>
       </ul>
       <ul>
          <li>gameName</li>
          <li>{{ item["gameName"] }}</li>
       </ul>
       <ul>
          <li>state</li>
          <li>{{ item["state"] }}</li>
       </ul>
       <ul>
          <li>bettingType</li>
          <li>{{ item["bettingType"] }}</li>
       </ul>
       <ul>
          <li>time</li>
          <li>{{ item["time"] }}</li>
       </ul>
       <ul>
          <li>amount</li>
          <li>{{ item["amount"] }}</li>
       </ul>
       <ul>
          <li>betval</li>
          <li>{{ item["betval"] }}</li>
       </ul>
       <ul>
          <li>ext</li>
          <li>{{ item["ext"] }}</li>
       </ul>
       <ul>
          <li>userFlag</li>
          <li>{{ item["userFlag"] }}</li>
       </ul>
       <ul>
          <li>payment</li>
          <li>{{ item["payment"] }}</li>
       </ul>
       <ul>
          <li>serialNo</li>
          <li>{{ item["serialNo"] }}</li>
       </ul>
       <ul>
          <li>tableId</li>
          <li>{{ item["tableId"] }}</li>
       </ul>
       <ul>
          <li>result</li>
          <li>{{ item["result"] }}</li>
       </ul>
       <ul>
          <li>baccarat</li>
          <li>{{ item["baccarat"] }}</li>
       </ul>
       <ul>
          <li>baccarat64</li>
          <li>{{ item["baccarat64"] }}</li>
       </ul>
       <ul>
          <li>openingTime</li>
          <li>{{ item["openingTime"] }}</li>
       </ul>
       <ul>
          <li>paymentTime</li>
          <li>{{ item["paymentTime"] }}</li>
       </ul>
       <ul>
          <li>validBet</li>
          <li>{{ item["validBet"] }}</li>
       </ul>
       <ul>
          <li>valid</li>
          <li>{{ item["valid"] }}</li>
       </ul>
       <ul>
          <li>noComm</li>
          <li>{{ item["noComm"] }}</li>
       </ul>
       <ul>
          <li>bettingTypeName</li>
          <li>{{ item["bettingTypeName"] }}</li>
       </ul>
       <ul>
          <li>resultMasterPics</li>
          <li>{{ item["resultMasterPics"] }}</li>
       </ul>
       <ul>
          <li>resultCluserPics</li>
          <li>{{ item["resultCluserPics"] }}</li>
       </ul>
       <ul>
          <li>resultCluser2Pics</li>
          <li>{{ item["resultCluser2Pics"] }}</li>
       </ul>
       <ul>
          <li>resultCluser3Pics</li>
          <li>{{ item["resultCluser3Pics"] }}</li>
       </ul>
       <ul>
          <li>zvn</li>
          <li>{{ item["zvn"] }}</li>
       </ul>
       <ul>
          <li>x1vn</li>
          <li>{{ item["x1vn"] }}</li>
       </ul>
       <ul>
          <li>x2vn</li>
          <li>{{ item["x2vn"] }}</li>
       </ul>
       <ul>
          <li>x3vn</li>
          <li>{{ item["x3vn"] }}</li>
       </ul>
       <ul>
          <li>betResult</li>
          <li>{{ item["betResult"] }}</li>
       </ul>
       <ul>
          <li>fmtBetResult</li>
          <li>{{ item["fmtBetResult"] }}</li>
       </ul>
       <ul>
          <li>loginId</li>
          <li>{{ item["loginId"] }}</li>
       </ul>
       <ul>
          <li>transactionKey</li>
          <li>{{ item["transactionKey"] }}</li>
       </ul>
    </div>
  </template>
</template>

<script>
export default {
  name: 'vendorCode2',
  props: ['detailData']
}
</script>

<style scoped></style>
<script setup></script>
